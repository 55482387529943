//src/components/ResetPasswordForm.js
import React, { useState } from 'react';
import api from '../services/api';
import Spinner from './Spinner';
import Message from './Message';

function verificaRequisitosSenha(senha) {
  return {
    comprimento: senha.length >= 8 && senha.length <= 10,
    letraMaiuscula: /[A-Z]/.test(senha),
    letraMinuscula: /[a-z]/.test(senha),
    digito: /[0-9]/.test(senha),
    caracterNaoAlfanumerico: /[^A-Za-z0-9]/.test(senha),
  };
}


function ResetPasswordForm({onBackToTokenForm  }) {
  const [token, setToken] = useState('');
  const [UserPassword, setUserPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState(''); 
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [requisitosSenha, setRequisitosSenha] = useState({
    comprimento: false,
    letraMaiuscula: false,
    letraMinuscula: false,
    digito: false,
    caracterNaoAlfanumerico: false,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [mostrarRequisitos, setMostrarRequisitos] = useState(false);

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handleFormBlur = (e) => {
    // Verifica se o foco não está indo para um dos elementos filhos do formulário
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setMostrarRequisitos(false);
      setIsPasswordFocused(false);

    }
  };

  const handlePasswordChange = (e) => {
    const novaSenha = e.target.value;
    setUserPassword(novaSenha);
    setRequisitosSenha(verificaRequisitosSenha(novaSenha));
    setMostrarRequisitos(!message, verificaRequisitosSenha(novaSenha));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requisitosAtendidos = Object.values(requisitosSenha).every(Boolean);
    if (!requisitosAtendidos) {
      setMessage('A senha não atende a todos os requisitos necessários.');
      setMessageStatus('error');
      return; // Impede a submissão do formulário
    }
    setIsLoading(true);
    setMessage('');
    setMessageStatus('');
    try {
      const response = await api.patch('/resetPassword', { token, UserPassword });
      setMessage(response.data.message);
      setMessageStatus(response.status);
      setTimeout(() => {
        setMessage(''); // Limpa a mensagem
      }, 5000); // Duração da mensagem: 2000ms ou 2 segundos
    } catch (error) {
      let errorMessage = error.response && error.response.data && error.response.data.error
                        ? error.response.data.error
                        : 'Erro ao redefinir senha. Tente novamente.';
      setMessage(errorMessage);
      setMessageStatus(error.response ? error.response.status : 500);
    
      setTimeout(() => {
        setMessage(''); // Limpa a mensagem
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} onBlur={handleFormBlur}>
        {isLoading ? <Spinner /> : (
          <>
            <input
              type="text"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              placeholder="Token"
            />
            <input
              type={showPassword ? "text" : "password"}
              value={UserPassword}
              onChange={handlePasswordChange}
              onFocus={handlePasswordFocus}
              placeholder="Nova Senha"
            />
            <span onClick={togglePasswordVisibility}>
              {showPassword ? '👁️' : '👁️‍🗨️'}
            </span>
            <button
            type="submit"
            disabled={!Object.values(requisitosSenha).every(Boolean)}
          >
            Redefinir Senha
          </button>
          </>
        )}
      </form>
      {isPasswordFocused && !isLoading && !message && (
        <div className="requisitos-senha">
            {/* Renderiza a lista de requisitos somente se não estiver carregando */}
            {mostrarRequisitos && (
              <ul>
                <li style={{ color: requisitosSenha.comprimento ? 'green' : 'red' }}>Entre 8 e 10 caracteres</li>
                <li style={{ color: requisitosSenha.letraMaiuscula ? 'green' : 'red' }}>1 letra maiúscula</li>
                <li style={{ color: requisitosSenha.letraMinuscula ? 'green' : 'red' }}>1 letra minúscula</li>
                <li style={{ color: requisitosSenha.digito ? 'green' : 'red' }}>1 dígito</li>
                <li style={{ color: requisitosSenha.caracterNaoAlfanumerico ? 'green' : 'red' }}>1 caractere não alfanumérico</li>
              </ul>
            )}
            
        </div>
      )}
      <div className="mensagem-resposta">
      {/* Renderiza a mensagem somente se não estiver carregando */}
      {!isLoading && message && (
        <div className="mensagem-resposta">
          <Message status={messageStatus} text={message} />
        </div>
      )}
      </div>
    </div>
  );
}

export default ResetPasswordForm;


