// src/App.js
import React, { useState } from 'react';
import RequestTokenForm from './components/RequestTokenForm';
import ResetPasswordForm from './components/ResetPasswordForm';

function App() {
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);

  const handleTokenRequested = () => {
    setShowResetPasswordForm(true);
  };
  

  const handleBackToTokenForm = () => {
    setShowResetPasswordForm(false);
  };

  return (
    <div className="App">
      {showResetPasswordForm ? (
        <ResetPasswordForm onBackToTokenForm={handleBackToTokenForm} />
      ) : (
        <RequestTokenForm onTokenRequested={handleTokenRequested} />
      )}
    </div>
  );
}

export default App;
